:root {
  --main: #406e84;
  --secondary: #eb9d5d;
  --mid-light: #d2cfdf;
  --light: #fff4eb;
  --banger-btn: #c40202;
}

.body-login {
  background-image: url('./assets/images/back_image.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.main-btn {
  color: white;
  border: 0;
  background-color: var(--main);
}

.main-btn:focus {
  background-color: var(--main);
}

.second-btn {
  color: white;
  border: 0;
  background-color: var(--secondary);
}

.second-btn:focus {
  background-color: var(--secondary);
}

.second-btn:hover {
  background-color: #eb9d5d90;
}

.second-btn:disabled {
  background-color: #eb9d5d90;
}

.main-btn:disabled {
  background-color: #406f846c;
}

.main-btn:hover {
  background-color: #406f846c;
}

.dash-card {
  cursor: pointer;
  transition: transform 0.4s;
}

.dash-card:hover {
  transform: scale(
    1.2
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

/* Styles for the mobile numbers list container */
.mobile-numbers-container {
  padding: 10px; /* Padding around the list */
  border: 1px solid #ccc; /* Border around the list */
  border-radius: 5px; /* Rounded corners */
}

/* Styles for the header row */
.header-row {
  background-color: #f0f0f0; /* Background color for the header */
  padding: 10px;
  border-bottom: 1px solid #ccc; /* Border below the header */
  display: flex;
  justify-content: space-around;
}

/* Styles for the header cell */
.header-cell {
  font-weight: bold; /* Bold font for the header */
}

/* Styles for each row in the list */
.list-row {
  padding: 10px; /* Padding around each row */
  border-bottom: 1px solid #ccc; /* Border between rows */
}

.chat-bubble {
  padding: 10px;
  border-radius: 15px;
  display: inline-block;
  max-width: 65%;
  font-size: 15px;
}

/* Style for short text */
.short-text {
  background-color: lightblue;
}

/* Style for medium text */
.wa-text {
  border: solid 1px gray;
  box-shadow: 5px 1px 5px gray;
  background-color: white;
}

.medium-text {
  background-color: lightgreen;
}

/* Style for long text */
.long-text {
  background-color: lightcoral;
}

/* Class to align chat bubble to the right */
.align-right {
  margin-left: auto; /* This will align the chat bubble to the right */
  text-align: right;
}

.align-left {
  margin-left: auto; /* This will align the chat bubble to the right */
  text-align: left;
}

.fullscreen-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  justify-content: center;
  align-items: center;
}

.fullscreen-overlay.visible {
  display: flex;
}

.mobile-view {
  background-image: url('./assets/images/phone_035.jpg');
  background-size: 300px;
  background-repeat: no-repeat;
  height: 820px;
  position: relative;
}

/* .gallery-img {
  width: 80px;
} */

.delete-icon {
  position: absolute;
  top: 30%;
  left: 70%;
  transform: translate(-50%, -50%);
  background-color: red;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: none; /* Initially hide the delete icon */
}

.delete-icon i {
  color: red;
}

.image-container:hover .delete-icon {
  display: block; /* Show delete icon on hover */
}
